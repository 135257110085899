import React from "react";
import nubitlogo from "../img/nubitlogo.png";
import { FaRotate } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import western from '../img/westernlogo.png'
import money from '../img/moneygramlogo.jpg'
import ria from '../img/rialogo.png'
import "swiper/swiper-bundle.css";
import { Autoplay } from "swiper/modules";
import TypingEffect from "react-typing-effect";

// const data = [
//   {
//     id: 1,
//     value:
//       "| Lorem Ipsum is simply dummy text of the printing and typesetting industry. |",
//   },
//   {
//     id: 2,
//     value: "| Lorem Ipsum is simply dummy . | ",
//   },
//   {
//     id: 3,
//     value: "| Lorem Ipsum is simply dummy text of the . | ",
//   },
//   // more data ...
// ];

const Footer = (props) => {
  const slides = [
    {
      src: western,
      width: 150,
      alt: "Slide 1",
      head: "Wester Union",
      description: "Helping people and businesses save, spend, and transfer money for a better financial future."
    },
    {
      src: ria,
      alt: "Slide 2",
      width: 100,
      head: "Ria Money Transfer",
      description: "Ria helps people send money globally, expanding to major countries and launching RiaMoneyTransfer.com with partnerships like Walmart-2-Walmart."
    },
    {
      src: money,
      width: 100,
      alt: "Slide 3",
      head: "Money Gram",
      description: "MoneyGram offers global money transfers, bill payments, and financial services with affordable rates, trusted by 150M+ consumers for 80+ years."
    },
    // Add more slides here
  ];

  const parts = [
    "Karachi, Lahore, Peshawar, Islamabad, Bhawalpur, Burewala",
    "Chaiwanda, Daska, Dera Ghazi Khan, Dinga, Faislabad, Farooqabad",
    "Gojra, Gujar Khan, Gujranwala, Gujrat, Hafizabad, Hazro",
    "Jalalpur Jattan, Jaranwala, Jhang, Jhelum, Khariyan, Kohat",
    "Kotla, Lalamusa, Layyah, Mandi Bahuddin, Mardan, Mingora",
    "Mirpur, Multan, Nankana Sahib, Narowal, Pak Pattan, Pasrur",
    "Phalia, Pir Mahal, Rahim Yar Khan, Sambrial, Sangla Hill",
    "Sargodha, Sheikhupura, Sialkout, Swabi, Vehari",
  ];
  return (
    <div className="h-full font-dm flex bg-theme1-100 items-center lg:py-7 py-6 px-4 justify-center w-full overflow-hidden cursor-pointer" onClick={() => props.onClick()} >
      {/* <span></span> */}

      <div className="flex items-center justify-center w-full">
        <Swiper
          className="h-full overflow-hidden flex justify-center items-center"
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false, 
          }}
          onSlideChange={(swiper) => {
          
            const slowSlideIndex = swiper.slides.length - 1; 

            if (swiper.realIndex === slowSlideIndex) {
              swiper.params.autoplay.delay = 44000; 
            } else {
              swiper.params.autoplay.delay = 6000; 
            }
            swiper.autoplay.start(); 
          }}
        >
          <SwiperSlide className="h-full overflow-hidden flex justify-center items-center">
            <div className="flex items-center w-full justify-center">
              <span className="font-bold  lg:text-xl text-lg text-[#6F183D] tracking-widest">
                Powered By
              </span>
              <img

                src={nubitlogo}
                className="h-full ml-1 cursor-pointer"
                width={120}
              />
            </div>
          </SwiperSlide>
          {slides.map((slide) => {
            return (
              <SwiperSlide className="h-full overflow-hidden flex justify-center items-center">
                <div className="flex items-center w-full justify-center">

                  <img
                    src={slide.src}
                    className="h-24 ml-1 cursor-pointer"
                    width={slide.width}

                  />
                  <div className="flex flex-col px-2">
                    <span className="font-bold  lg:text-xl text-lg text-[#6F183D] tracking-widest">
                      {slide.head}
                    </span>
                    <span className="font-bold  lg:text-sm text-sm text-[#6F183D] tracking-widest">
                      {slide.description}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
          <SwiperSlide className="h-full overflow-hidden flex justify-center items-center">
            <div className="flex flex-col items-center w-full justify-center">
              <span className="h-full w-full flex  justify-center items-center font-bold  lg:text-xl text-lg text-[#6F183D] tracking-widest">
                Trusted across 70+ branches nationwide.🌐
              </span>
              <span className="h-full w-full flex  justify-center items-center font-bold  lg:text-xl text-lg text-[#6F183D] tracking-widest">
                Proudly serving customers across 40+ cities nationwide
              </span>


            </div>
          </SwiperSlide>


          <SwiperSlide className="h-full overflow-hidden flex justify-center items-center">
            <div className="flex flex-col items-center w-full justify-center">
              <span className="h-full w-full flex  justify-center items-center font-bold  lg:text-2xl text-lg text-[#6F183D] tracking-widest">
                ★ We are proud to be available in the following cities:
              </span>
              <span className="h-full w-full flex justify-center items-center font-bold  text-lg text-[#6F183D] tracking-widest">
                <TypingEffect
                  text={[
                    "Bhawalpur, Daska, Dera Ghazi Khan, Dinga, Faislabad, Farooqabad ",
                    "Gojra, Gujar Khan, Jaranwala, Jhang, Sialkout, Swabi, Vehari, Mardan",
                    "Gujranwala, Gujrat, Hafizabad, Hazro, Islamabad, Jalalpur Jattan",
                    "Karachi, Khariyan, Kohat, Kotla, Lahore, Layyah, Mandi Bahuddin, Mirpur",
                    "Multan, Nankana Sahib, Narowal, Pak Pattan, Pasrur, Phalia, Pir Mahal ",
                    "Rahim Yar Khan, Sambrial, Sangla Hill, Sargodha, Sheikhupura, Mingora",
                  ]}
                  speed={50} // Typing speed
                  eraseSpeed={30} // Erase speed
                  eraseDelay={2000} // Delay before erasing
                  typingDelay={400} // Delay before starting
                />
              </span>



            </div>
          </SwiperSlide>
        </Swiper>


      </div>

    </div>




  );
};

export default Footer;






{/* <span className="h-full w-full flex justify-center items-center font-bold  text-sm text-[#6F183D] tracking-widest">
                Karachi, Lahore, Peshawar, Islamabad, Bhawalpur, Burewala, Chaiwanda, Daska, Dera Ghazi Khan,
                Dinga, Faislabad, Farooqabad, Gojra, Gujar Khan, Gujranwala, Gujrat, Hafizabad, Hazro, Jalalpur Jattan, Jaranwala, Jhang, Jhelum, Khariyan, Kohat, Kotla,
                Lalamusa, Layyah, Mandi Bahuddin, Mardan, Mingora, Mirpur, Multan, Nankana Sahib, Narowal, Pak Pattan, Pasrur, Phalia, Pir Mahal,
                Rahim Yar Khan, Sambrial, Sangla Hill, Sargodha, Sheikhupura, Sialkout, Swabi, Vehari

              </span> */}